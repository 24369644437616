import './App.css';


function App() {
  return (
    <div className="App">
      <div className='tgme_background_wrap'></div>
      <div className='tgme_page'>
        <div className='tgme_page_photo'>
          <a href="tg://join?invite=b7l6wt-UHCJkNDBi">
            <div class="tgme_page_photo_image"></div>
          </a>
        </div>
        <div class="tgme_page_title" dir="auto">
          <span dir="auto">Flört Günlüğü 🔞</span>
        </div>
        <div class="tgme_page_description" dir="auto">Ben Osman, sana kadınlarla nasıl tanışıp iletişim kuracağını ve işleri nasıl samimiyete taşıyabileceğini öğreteceğim 👉👌</div>
        <div class="tgme_page_action">
          <a class="tgme_action_button_new shine" href="tg://join?invite=b7l6wt-UHCJkNDBi">Join Channel</a>
        </div>
      </div>
    </div>
  );
}

export default App;
